<template>
  <div>
    <div class="row">
      <div class="col-lg-5 col-12">
        <div v-if="content == 'tambah'">
          <!-- === Form Tambah === -->
          <div class="card card-custom">
            <div class="card-body p-0">
              <!--begin: Wizard-->
              <div
                class="wizard wizard-1"
                id="kt_wizard_v1"
                data-wizard-state="step-first"
                data-wizard-clickable="true"
              >
                <!--begin: Wizard Body-->
                <div
                  class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-7">
                    <!--begin: Wizard Form-->
                    <form
                      class="form"
                      id="kt_form"
                      @submit.stop.prevent="sectionsAdd()"
                    >
                      <!--begin: Wizard Step 1-->
                      <div
                        class="pb-5"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                      >
                        <h3
                          class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                        >
                          Tambah Seksi
                        </h3>
                        <div class="form-group">
                          <label>Nama</label>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="nama"
                            placeholder=""
                            v-model="post.name"
                          />
                          <span class="form-text text-muted"
                            >Masukkan Nama Seksi</span
                          >
                        </div>
                        <div class="form-group">
                          <label>Seksi</label>
                          <treeselect
                            :options="classwork_types"
                            v-model="post.classwork_id"
                            :multiple="multiple"
                          >
                          </treeselect>
                        </div>
                        <div class="form-group">
                          <label>Deskripsi</label>
                          <b-form-textarea
                            id="textarea"
                            placeholder="Enter something..."
                            rows="3"
                            max-rows="6"
                            v-model="post.description"
                            >{{ post.description }}</b-form-textarea
                          >
                          <!-- <span class="form-text text-muted">Masukkan Nama</span> -->
                        </div>
                      </div>
                      <!--end: Wizard Step 1-->

                      <!--begin: Wizard Actions -->
                      <div
                        class="d-flex justify-content-between border-top pt-10"
                      >
                        <div>
                          <!-- <router-link
                            to="/masters/formats"
                            class="btn btn-info font-weight-bold text-uppercase px-9 py-4"
                          >
                            Batal
                          </router-link> -->
                          <button
                            class="btn btn-primary font-weight-bold text-uppercase px-9 py-4 ml-2"
                          >
                            Tambah
                          </button>
                        </div>
                      </div>
                      <!--end: Wizard Actions -->
                    </form>
                    <!--end: Wizard Form-->
                  </div>
                </div>
                <!--end: Wizard Body-->
              </div>
            </div>
            <!--end: Wizard-->
          </div>
          <!-- === Akhir Form Tambah === -->
        </div>
        <!-- === Form ubah === -->
        <div v-if="content == 'edit'">
          <div class="card card-custom">
            <div class="card-body p-0">
              <!--begin: Wizard-->
              <div
                class="wizard wizard-1"
                id="kt_wizard_v1"
                data-wizard-state="step-first"
                data-wizard-clickable="true"
              >
                <!--begin: Wizard Body-->
                <div
                  class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-7">
                    <!--begin: Wizard Form-->
                    <form
                      class="form"
                      id="kt_form"
                      @submit.stop.prevent="sectionsUpdate(edit.id)"
                    >
                      <!--begin: Wizard Step 1-->
                      <div
                        class="pb-5"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                      >
                        <h3
                          class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                        >
                          Edit Seksi
                        </h3>
                        <div class="form-group">
                          <label>Nama</label>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="nama"
                            placeholder=""
                            v-model="edit.name"
                          />
                          <span class="form-text text-muted"
                            >Masukkan Nama Sekolah</span
                          >
                        </div>
                        <div class="form-group">
                          <label>Deskripsi</label>
                          <b-form-textarea
                            id="textarea"
                            placeholder="Enter something..."
                            rows="3"
                            max-rows="6"
                            v-model="edit.description"
                            >{{ edit.description }}</b-form-textarea
                          >
                          <!-- <span class="form-text text-muted">Masukkan Nama</span> -->
                        </div>
                      </div>
                      <!--end: Wizard Step 1-->

                      <!--begin: Wizard Actions -->
                      <div
                        class="d-flex justify-content-between border-top pt-10"
                      >
                        <div>
                          <!-- <router-link
                            to="/masters/formats"
                            class="btn btn-info font-weight-bold text-uppercase px-9 py-4"
                          >
                            Batal
                          </router-link> -->
                          <button
                            class="btn btn-primary font-weight-bold text-uppercase px-9 py-4 ml-2"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <!--end: Wizard Actions -->
                    </form>
                    <!--end: Wizard Form-->
                  </div>
                </div>
                <!--end: Wizard Body-->
              </div>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!-- === Akhir Form Ubah === -->
        <!-- === detail === -->
        <div v-if="content == 'detail'">
          <div class="card card-custom">
            <div class="card-body p-0">
              <!--begin: Wizard-->
              <div
                class="wizard wizard-1"
                id="kt_wizard_v1"
                data-wizard-state="step-first"
                data-wizard-clickable="true"
              >
                <!--begin: Wizard Body-->
                <div
                  class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-7">
                    <!--begin: Wizard Step 1-->
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <h3
                        class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                      >
                        Detail Seksi
                      </h3>
                      <div
                        class="card mb-3 col-xl-12 col-xxl-7"
                        style="max-width: 540px"
                      >
                        <div class="row no-gutters">
                          <div class="card-body">
                            <h5>{{ detail.name }}</h5>
                            <hr />
                            <p>{{ detail.description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end: Wizard Step 1-->
                  </div>
                </div>
                <!--end: Wizard Body-->
              </div>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!-- === akhir detail === -->
      </div>

      <!-- listing data -->
      <div class="col-lg-7 col-12">
        <div class="card">
          <div class="card-body px-4 card-list">
            <div class="row">
              <div class="col-1">
                <div v-if="content == 'tambah'">
                  <button
                    @click="handleAdd()"
                    class="btn btn-secondary mb-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Tambah Seksi"
                  >
                    <i class="fas fa-plus w-75"></i>
                  </button>
                </div>
                <div v-if="content == 'detail' || content == 'edit'">
                  <button
                    @click="handleAdd()"
                    class="btn btn-primary mb-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Tambah Seksi"
                  >
                    <i class="fas fa-plus w-75"></i>
                  </button>
                </div>
              </div>
              <div class="col-6 ml-4">
                <div class="input-icon h-40px">
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid h-40px"
                    placeholder="Ketikkan Sesuatu..."
                    id="filter-input"
                    v-model="filter"
                  />
                  <span>
                    <span class="svg-icon svg-icon-lg">
                      <!--begin::Svg Icon-->
                      <inline-svg src="/media/svg/icons/General/Search.svg" />
                      <img src="" alt="" />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <b-table
                small
                :fields="fields"
                :items="sections"
                responsive
                id="my-table"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="filter"
                stacked="md"
                show-empty
                @filtered="onFiltered"
              >
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>

                <template #cell(description)="data">
                  {{
                    data.item.description.length > 49
                      ? data.item.description.substring(0, 50) + "..."
                      : data.item.description
                  }}
                </template>

                <template #cell(aksi)="data">
                  <button
                    @click="handleDetail(data.item.id)"
                    class="btn btn-sm btn-info ml-1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Detail Seksi"
                  >
                    <i class="fas fa-eye w-75"></i>
                  </button>
                  <button
                    @click="handleEdit(data.item.id)"
                    class="btn btn-sm btn-success ml-1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Edit Seksi"
                  >
                    <i class="fas fa-edit w-75"></i>
                  </button>
                  <button
                    @click="sectionsDelete(data.item.id)"
                    class="btn btn-sm btn-danger ml-1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Hapus Seksi"
                  >
                    <i class="fas fa-trash w-75"></i>
                  </button>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
                :class="'pagination-list'"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.th-no-sections {
  width: 35px;
}
.th-nama-sections {
  width: 230px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service.js";
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "master-sections",
  data() {
    return {
      multiple: false,
      content: "tambah",
      perPage: 5,
      currentPage: 1,
      totalRows: 1,
      filter: "",
      fields: [
        // A virtual column that doesn't exist in items
        {
          key: "index",
          label: "No",
          thClass: "th-no-sections text-center",
          tdClass: "text-center",
        },
        // A column that needs custom formatting
        { key: "name", label: "Nama", thClass: "th-nama-sections" },
        {
          key: "aksi",
          label: "Aksi",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      sections: [],
      detail: {},
      classwork_types: [],
      edit: {
        modified_by: 1,
      },
      post: {
        name: "",
        description: "",
        classwork_id: 2,
        status_id: 1,
        created_by: 1,
        modified_by: 1,
      },
    };
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setClassworkTypes(){
      ApiService.get("api/classwork-types")
        .then((response) => {
          //(response)
          this.classwork_types =response.data.data.map((elem) => ({
            id: elem.id,
            label: elem.name,
          }));
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    setModifiedBy() {
      this.dataUser = JSON.parse(window.localStorage.getItem("user"));
      this.post.modified_by = this.dataUser.id;
      this.post.created_by = this.dataUser.id;
    },
    setModifiedEdit() {
      this.dataUser = JSON.parse(window.localStorage.getItem("user"));
      this.post.modified_by = this.dataUser.id;
    },
    // tambah data
    sectionsAdd() {
      if (this.post.name) {
        this.setModifiedBy();
        //("data yang di input",this.post)
        ApiService.post("api/sections", this.post)
          .then((response) => {
            Swal.fire({
              title: "",
              text: "Data berhasil ditambah",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              //   heightAuto: false,
            });
            this.getSections();
            this.post.name = "";
            this.post.description = "";

            this.getSectionsById(response.data.data.id);
            this.content = "detail";
          })
          .catch(({ response }) => {
            //("responerror", response);
            Swal.fire({
              title: "",
              text: "Data gagal ditambah",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              //   heightAuto: false,
            });
          });
      } else {
        Swal.fire({
          title: "",
          text: "Data tidak diisi",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          //   heightAuto: false,
        });
      }
    },
    // handle content detail
    handleDetail(id) {
      this.content = "detail";
      this.getSectionsById(id);
    },
    //  handle content tambah
    handleAdd() {
      this.content = "tambah";
    },
    // ==== handle content edit
    handleEdit(id) {
      this.getSectionsById(id);
      this.content = "edit";
    },
    //get all data
    getSections() {
      ApiService.setHeader();
      ApiService.get("api/sections")
        .then((response) => {
          //(response);
          this.sections =response.data.data;
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    // get data by id
    getSectionsById(id) {
      ApiService.get("api/sections/" + id)
        .then((response) => {
          this.edit = response.data.data;
          this.detail = response.data.data;
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    // hapus data
    sectionsDelete(id) {
      Swal.fire({
        title: "Apakah kamu yakin ingin menghapus data?",
        showCancelButton: true,
        confirmButtonText: `Delete`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          ApiService.delete("api/sections/" + id)
            .then(() => {
              Swal.fire("Data berhasil dihapus", "", "success");
              this.getSections();

              this.content = "tambah";
            })
            .catch(() => {
              Swal.fire("Data gagal dihapus", "", "error");
            });
        } else {
          Swal.fire("Data batal dihapus", "", "info");
        }
      });
    },
    // update data
    sectionsUpdate(id) {
      if (this.edit.name && this.edit.description) {
        // set data modified
        this.setModifiedEdit();

        ApiService.put("api/sections/" + id, this.edit)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Data berhasil diedit",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              //   heightAuto: false,
            });
            this.getSections();
            this.edit.name = "";
            this.edit.description = "";

            this.getSectionsById(id);
            this.content = "detail";
          })
          .catch(({ response }) => {
            //("Data gagal diedit", response);
            Swal.fire({
              title: "",
              text: "Data gagal diedit",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              //   heightAuto: false,
            });
          });
      } else {
        Swal.fire({
          title: "",
          text: "Terdapat Form yang tidak diisi",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          //   heightAuto: false,
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Seksi" },
    ]);

    this.getSections();
    this.setClassworkTypes();
  },
};
</script>
